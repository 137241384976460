import cn from 'classnames';
import type { ISeoProps } from 'entities/Seo/types';
import cookies from 'js-cookie';
import { DialogRestorePassword } from 'modules/account/components/DialogRestorePassword';
import { DialogSignInSignUp } from 'modules/account/components/DialogSignInSignUp';
import { Footer } from 'modules/footer/components/Footer';
import { Header } from 'modules/header/components/Header';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EBreakpoints } from 'constants/breakpoints';
import { externalServicesUrls } from 'constants/externalServicesUrls';

import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { HtmlHead } from 'components/layouts/HtmlHead';
import { AgreementBanner } from 'components/shared/AgreementBanner';
import { DialogOffer } from 'components/shared/DialogOffer';
import {
  EPlaceSlug,
  IBannerData,
  ILitresBanner,
} from 'components/shared/LitresBanner/types';
import { MailConfirmation } from 'components/shared/MailConfirmation';

import type { TAppState } from 'sp-redux';

import { isBrowser, isTestEnvironment } from 'utils/environment';
import { fetchWrapper } from 'utils/fetchWrapper';

import s from './Page.module.scss';

interface IPageProps {
  children: ReactNode;

  fullHeight?: boolean;
  fullWidth?: boolean;
  isShortWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  grid?: boolean;
  breadCrumbs?: {
    name: string;
    link: string;
    style?: string;
  };
  className?: string;
  removeBottomPadding?: boolean;
  seo?: ISeoProps;
  withoutBanner?: boolean;
  handleMouseMove?: MouseEventHandler<HTMLDivElement>;
  handleMouseLeave?: () => void;
}

export const Page: FC<IPageProps> = ({
  children,
  withoutHeader,
  withoutFooter,
  removeBottomPadding,
  grid,
  breadCrumbs,
  fullHeight,
  fullWidth,
  isShortWidth,
  className,
  seo,
  withoutBanner,
  handleMouseLeave,
  handleMouseMove,
}) => {
  const state: TAppState = useSelector((appState: TAppState) => appState);
  const { isLogged } = state.userInfo;
  const { is_offer_agreement_accepted, is_email_confirmed, is_publisher } =
    state.userInfo.profile;
  const [isAgreementShown, setIsAgreementShown] = useState(false);
  const [bannerData, setBannerData] = useState<ILitresBanner | null>(null);
  const [showOfferModal, setShowOfferModal] = useState(false);

  const router = useRouter();

  const isMobile = isBrowser && window.innerWidth < EBreakpoints.MD;

  const isCoverEditorPage = router.pathname.includes('cover-editor');
  const isCreatePage = router.pathname.includes('create');

  useEffect(() => {
    const agreementCookie = cookies.get('agreement');
    if (!agreementCookie) {
      setIsAgreementShown(true);
    }
  }, []);

  const setAgreementCookie = (): void => {
    cookies.set('agreement', 'accepted', { expires: 365 });
    setIsAgreementShown(false);
  };

  useEffect(() => {
    if (is_offer_agreement_accepted === false) {
      setShowOfferModal(true);
    }
  }, [is_offer_agreement_accepted]);

  const getLitresBanner = async (): Promise<Response> => {
    const placeSlug = EPlaceSlug.HeaderFullWidth;

    let url = isTestEnvironment()
      ? externalServicesUrls.litresBanner.master(placeSlug)
      : externalServicesUrls.litresBanner.production(placeSlug);

    const options = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-id': '2',
        Accept: 'application/json',
      },
    };

    if (isMobile) {
      url = url + '?device_type=mobile';
    }

    return await fetchWrapper(url, options);
  };

  const getBannerInfo = async (): Promise<void> => {
    try {
      const data = await getLitresBanner();
      const result = (await data.json()) as {
        payload: { data: IBannerData[] };
      };
      const payload = result.payload.data;

      if (payload.length !== 0) {
        const {
          banner_title,
          image_url,
          transition_link,
          advertising_marking,
          event_data,
        } = payload[0];
        setBannerData({
          title: banner_title,
          url: image_url,
          link: transition_link,
          mark: advertising_marking,
          event_data,
        });
      }
    } catch (e) {
      setBannerData(null);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (!withoutBanner && !is_publisher) {
      getBannerInfo();
    }
  }, []);

  return (
    <>
      <HtmlHead {...seo} />
      <div
        className={cn(s.root, {
          [s.hasHeaderBanner]: bannerData,
        })}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <a
          href="#start-of-content"
          className={cn('visually-hidden', s.skipLink)}
        >
          Перейти к контенту страницы
        </a>
        {!withoutHeader && (
          <Header userInfo={state.userInfo} bannerData={bannerData} />
        )}

        <main
          className={cn(s.contentContainer, className, {
            grid,
            [s.fullHeight]: fullHeight,
            [s.fullWidth]: fullWidth,
            [s.shortWidth]: isShortWidth,
            [s.removeBlockEndPadding]: removeBottomPadding,
            [s.hasBreadCrumbs]: breadCrumbs,
          })}
          id="start-of-content"
        >
          {breadCrumbs && (
            <FunctionalButton
              className={cn(s.arrowBreadCrumbs, breadCrumbs.style)}
              icon="arrowLeftOutline24x24"
              iconPosition="left"
              href={breadCrumbs.link}
            >
              {breadCrumbs.name}
            </FunctionalButton>
          )}
          {!is_email_confirmed &&
            isLogged &&
            !isCoverEditorPage &&
            !isCreatePage && (
              <MailConfirmation
                className={cn({
                  [s.contentContainerAlert]: fullWidth,
                })}
              />
            )}
          {children}
          {isAgreementShown && (
            <AgreementBanner setAgreementCookie={setAgreementCookie} />
          )}
        </main>
        {!withoutFooter && <Footer />}
      </div>
      <DialogSignInSignUp />
      <DialogRestorePassword />
      <DialogOffer
        isOpen={
          showOfferModal &&
          !router.pathname.includes('statistics/natural-person')
        }
        onClose={(): void => setShowOfferModal(false)}
      />
    </>
  );
};
