/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/nextjs';

import { showError } from 'components/UIKit/Toaster/helpers/toast';

const showDefaultError = (message: any, error: any): void => {
  Sentry.captureException('Frontend received a custom error from the backend', {
    extra: {
      error,
    },
  });

  showError(message, {
    position: 'top-center',
  });
};

//Исользуем any так как по факту бэкенд может присылать не то, что описано в IAPIResponse
export const displayErrorFromBackendIfExists = (
  response: any,
  onNoErrors?: () => void,
  onError?: () => void,
): void => {
  // Проверяем 1й формат ошибки (стандартный)
  const case1 = response.payload?.errors?.length;
  if (case1) {
    const error = response.payload.errors[0];
    if (error.messages?.length) {
      const message = error.messages[0];
      showDefaultError(message, error);

      if (onError) {
        onError();
      }
      return;
    }
  }

  // Проверяем 2й формат ошибки (приходит если фронт генерит неверный URL эндпоинта) или бэкенд возвращает 404
  const case2 = response.error?.message;
  if (case2) {
    Sentry.captureException(
      'Frontend recieved 404 or other error from the backend after generating an incorrect URL',
      {
        extra: { error: response.error },
      },
    );

    // eslint-disable-next-line no-console
    console.trace(response.error);

    showError('Ошибка 404. Обратитесь в техподдержку.', {
      position: 'top-center',
    });

    if (onError) {
      onError();
    }
  }

  // 3й формат ошибки (аналогичный 1му, но другое поле)
  const case3 = response.errors?.length;
  if (case3) {
    const error = response.errors[0];
    if (error.messages?.length) {
      const message = error.messages[0];
      showDefaultError(message, error);

      if (onError) {
        onError();
      }
      return;
    }
  }

  if (onNoErrors && !case1 && !case2 && !case3) {
    onNoErrors();
  }
};
