import { uuid4 } from '@sentry/utils';

import { Icon } from 'components/UIKit/Icon';

import type { IToastOptions, ToastContent } from '../Toast';
import { toast } from '../Toast';

type TShowToast = (message: ToastContent, options?: IToastOptions) => void;

export const showSuccess: TShowToast = (message, options) => {
  toast.success(message, {
    wideness: 'wide',
    style: {
      backgroundColor: 'var(--palettes-green-10)',
      color: 'var(--palettes-green-100)',
    },
    icon: () => <Icon size={24} icon="checkCircleOutline24x24" />,
    toastId: uuid4(),
    ...options,
  });
};

export const showError: TShowToast = (message, options) => {
  toast.error(message, {
    wideness: 'wide',
    style: {
      color: 'var(--palettes-red-100)',
      backgroundColor: 'var(--palettes-red-10)',
    },
    icon: () => <Icon size={24} icon="errorOutline24x24" />,
    toastId: uuid4(),
    ...options,
  });
};

export const showInfo: TShowToast = (message, options) => {
  toast.info(message, {
    wideness: 'wide',
    style: {
      color: 'var(--palettes-blue-100)',
      backgroundColor: 'var(--palettes-blue-10)',
    },
    icon: () => <Icon size={24} icon="infoOutline24x24" />,
    toastId: uuid4(),
    ...options,
  });
};

export const showWarning: TShowToast = (message, options) => {
  toast.warning(message, {
    wideness: 'wide',
    style: {
      color: 'var(--palettes-yellow-100)',
      backgroundColor: 'var(--palettes-yellow-10)',
    },
    icon: () => <Icon size={24} icon="infoOutline24x24" />,
    toastId: uuid4(),
    ...options,
  });
};
